
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class SqidCollapsibleCard extends Vue {
  @Prop({ required: true }) private header!: string
  @Prop({ required: true }) private id!: string
  @Prop({ default: false, type: Boolean }) private narrow!: boolean

  private get collapseId() {
    return `collapse-${this.id}`
  }
}
