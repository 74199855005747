import { render, staticRenderFns } from "./SqidCollapseButton.vue?vue&type=template&id=972a95ac&scoped=true"
import script from "./SqidCollapseButton.vue?vue&type=script&lang=ts"
export * from "./SqidCollapseButton.vue?vue&type=script&lang=ts"
import style0 from "./SqidCollapseButton.vue?vue&type=style&index=0&id=972a95ac&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "972a95ac",
  null
  
)

export default component.exports