
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SqidCollapseButton extends Vue {
  @Prop({ required: true }) private id!: string

  private get collapseId() {
    return `collapse-${this.id}`
  }
}
