
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Rank, Snak as SnakData } from '@/api/types'
import SnakValue from '@/components/SnakValue.vue'

@Component({
  components: {
    'snak-value': SnakValue,
  }})
export default class Snak extends Vue {
  @Prop({ required: true }) private snak!: SnakData
  @Prop({ default: 'normal' }) private rank!: Rank
  @Prop({ default: false, type: Boolean }) private reverse!: boolean
  @Prop({ default: false, type: Boolean }) private short!: boolean

  private get deprecated() {
    return this.rank === 'deprecated'
  }

  private get preferred() {
    return this.rank === 'preferred'
  }
}
