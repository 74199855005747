
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { i18n } from '@/i18n'

@Component
export default class Home extends Vue {
  @Action private requestLabels: any

  private get language() {
    return i18n.locale
  }

  @Watch('language')
  private updateLanguage() {
    this.requestLabels({
      entityIds: ['Q1339', 'Q8072', 'Q318', 'P21', 'P1303', 'Q18616576', 'P31', 'P279'],
      lang: this.language,
    })
  }

  private created() {
    this.updateLanguage()
  }
}
