
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Snak as SnakData } from '@/api/types'

@Component
export default class SnakValue extends Vue {
  @Prop({ required: true }) private snak!: SnakData
  @Prop({ default: false, type: Boolean }) private short!: boolean

  private get snaktype() {
    return this.snak.snaktype
  }
}
